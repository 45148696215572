<template>
<b-row>
    <b-col md="5">
        <!-- badge -->

        <b-card>
            <b-form-group>
                <b-row>
                    <b-col cols="1" class="p-0">
                        <b-button block v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" variant="flat-primary" @click="minus_day(1)">
                            <feather-icon icon="ChevronLeftIcon"/>
                        </b-button>
                    </b-col>
                    <b-col cols="10" class="p-0">
                        <flat-pickr class="form-control" v-model="display_date" :config="{ altInput: true,altFormat: 'l F j, Y', dateFormat: 'Y-m-d',}"/>
                    </b-col>
                    <b-col  cols="1" class="p-0">
                        <b-button block v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" variant="flat-primary" @click="add_day(1)">
                            <feather-icon icon="ChevronRightIcon"/>
                        </b-button>
                    </b-col>
                </b-row>
            </b-form-group>
            <b-list-group>
                <b-list-group-item
                    @click="get_class_detail(class_detail.id,class_detail.class_date)"
                    class="pointer"
                    v-for="(class_detail, index) in classes.classes"
                    :key="index">
                    <b-row>
                        <b-col md="3">
                            <div class="text-center">{{class_detail.start}}</div>
                            <div class="text-center" style="border-top:1px solid #ccc">{{class_detail.end}}</div>
                        </b-col>
                        <b-col md="4">
                            <div>
                                <b>{{class_detail.name}}</b>
                            </div>
                            <div v-if="class_detail.status == 'CANCELLED' || class_detail.status == 'DISABLED'" style="color:red;">{{class_detail.status}}</div>
                        </b-col>
                        <b-col md="5" class="text-right">
                            <b-badge variant="primary" pill class="badge-round mr-1">
                                <span v-if="class_detail.existing_booking < class_detail.maximum">{{class_detail.existing_booking}}</span>
                                <span v-if="class_detail.existing_booking >= class_detail.maximum">{{class_detail.maximum}}</span>
                                /
                                {{class_detail.maximum}}</b-badge>
                            <b-badge variant="warning" pill class="badge-round" v-if="class_detail.existing_booking < class_detail.maximum">0 /
                                {{class_detail.waiting}}</b-badge>
                            <b-badge variant="warning" pill class="badge-round" v-if="class_detail.existing_booking >= class_detail.maximum">{{ (class_detail.existing_booking - class_detail.maximum )}}
                                /
                                {{class_detail.waiting}}</b-badge>
                        </b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>
        </b-card>
    </b-col>
    <b-col md="7">

        <b-card>
            <b-alert variant="danger" :show="class_detail.id == null">
                <div class="alert-body">
                    <span>
                        <strong>請選擇班別</strong>
                    </span>
                </div>
            </b-alert>
            <b-row v-if="class_detail.id != null">
                <b-col md="4">
                    <b-row>
                        <!-- first name -->
                        <b-col cols="12">
                            <b-form-group label="Class ID" label-for="class-id" size="sm">
                                <b-form-input id="class-id" placeholder="Class ID" readonly value="20418" v-model="class_detail.id" size="sm"/>
                            </b-form-group>
                        </b-col>

                        <!-- email -->
                        <b-col cols="12" v-if="class_detail.type != 'REGULAR'">
                            <b-form-group label="Date" label-for="Date">
                                <b-form-input id="class-date" placeholder="Class Date" v-model="class_detail.class_date" size="sm"/>
                            </b-form-group>
                        </b-col>

                        <!--<b-col cols="12"> <b-form-group label="Class Type" label-for="type" > <b-form-input id="type" value="REGULAR" size="sm" v-model="class_detail.type" readonly
                        /> </b-form-group> </b-col>-->
                        <b-col cols="12">
                            <b-form-group label="Class Type" label-for="type">
                                <v-select v-model="class_detail.status" :options="statusOptions" :clearable="true" :filterable="false" input-id="Client" :reduce="status => status.value"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Start Time" label-for="start">
                                <b-form-input id="start" placeholder="Start Time (hh:ii)" v-model="class_detail.start" size="sm"/>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-form-group label="End Time" label-for="end">
                                <b-form-input id="end" placeholder="End Time (hh:ii)" v-model="class_detail.end" size="sm"/>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" v-if="class_detail.type == 'REGULAR'">
                            <b-form-group label="Class Week" label-for="week">
                                <v-select v-model="class_detail.regular_weekday_number" label="label" :options="weekOptions" :clearable="false" class="select-size-sm"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Name" label-for="name">
                                <b-form-input id="name" size="sm" placeholder="Name" v-model="class_detail.name"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Instructor" label-for="Instructor">
                                <b-form-input id="Instructor" size="sm" v-model="class_detail.tutor" placeholder="Instructor Name"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Instructor Mobile" label-for="Instructor Mobile">
                                <b-form-input id="Instructor_Mobile" size="sm" v-model="class_detail.tutor_mobile" placeholder="Instructor Mobile"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Maximum" label-for="Maximum">
                                <b-form-input id="Maximum" size="sm" v-model="class_detail.maximum" placeholder="Maximum Number"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Waiting List" label-for="Waiting List">
                                <b-form-input id="Waiting List" size="sm" v-model="class_detail.waiting" placeholder="Waiting List"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Minimum" label-for="Minimum">
                                <b-form-input id="Minimum" size="sm" v-model="class_detail.minimum" placeholder="Minimum"/>
                            </b-form-group>
                        </b-col>

                        <!-- submit and reset -->
                        <b-col cols="12" class="mt-1">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" @click="update_class_detail()">
                                Update
                            </b-button>
                            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" v-if="class_detail.booking.length < 1" @click="delete_class(class_detail.id)">
                                Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="8">
                    <b-input-group class="input-group-merge mb-1">
                        <b-input-group-prepend is-text>
                            <feather-icon icon="SearchIcon"/>
                        </b-input-group-prepend>
                        <b-form-input placeholder="Add Booking..." class="text-uppercase" v-model="form.member_code" @keyup.enter.native="add_booking()"/>
                    </b-input-group>
                    <b-list-group>
                        <b-list-group-item v-for="(booking, index) in class_detail.booking" :key="index" v-if="booking.rank <= class_detail.maximum">
                            <b-row :class="booking.rank > class_detail.maximum ? 'yellow' : ''">
                                <b-col md="2">
                                    {{booking.member_code}}
                                </b-col>
                                <b-col md="6">
                                    <b-link :to="'/apps/member/edit/'+booking.token">
                                        <span>{{booking.rank}}.
                                            {{booking.zh_name}}
                                            {{booking.en_name}}</span>
                                    </b-link>
                                </b-col>
                                <b-col md="4" class="text-right">
                                    <feather-icon v-if="booking.is_attend !='Y' " @click="attend(booking.id)" icon="CheckSquareIcon" color="green" class="mr-1 pointer"/>
                                    <feather-icon v-if="booking.is_attend =='Y' " @click="unattend(booking.id)" icon="CheckIcon" color="blue" class="mr-1 pointer"/>
                                    <feather-icon v-if="booking.is_attend !='Y' " @click="delete_booking(booking.id)" icon="XIcon" color="red" class="pointer"/>
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                        <br>
                            <b-list-group-item v-for="(booking, index) in class_detail.booking" :key="index" v-if="booking.rank > class_detail.maximum" class="yellow">
                                <b-row>
                                    <b-col md="2">
                                        {{booking.member_code}}
                                    </b-col>
                                    <b-col md="6">
                                        <b-link :to="'/apps/member/edit/'+booking.member_id">
                                            <span>{{booking.rank}}.
                                                {{booking.zh_name}}
                                                {{booking.en_name}}</span>
                                        </b-link>
                                    </b-col>
                                    <b-col md="4" class="text-right">
                                        <feather-icon v-if="booking.is_attend !='Y' " @click="attend(booking.id)" icon="CheckSquareIcon" color="green" class="mr-1"/>
                                        <feather-icon v-if="booking.is_attend =='Y' " icon="CheckIcon" color="blue" class="mr-1"/>
                                        <feather-icon v-if="booking.is_attend !='Y' " @click="delete_booking(booking.id)" icon="XIcon" color="red"/>
                                    </b-col>
                                </b-row>
                            </b-list-group-item>

                        </b-list-group>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>

    </b-row>
</template>

<script>
import { BLink,BAlert,BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormTextarea,BTable, BListGroup, BListGroupItem, BFormSelect, BBadge, BRow, BCol, BCard, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BLink,
      BInputGroup, BInputGroupPrepend, BInputGroupAppend,  BFormTextarea,flatPickr,BAlert,
      BTable,
    vSelect,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BBadge,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
  },
    directives: {
    Ripple,
  },
  watch: {
      display_date() {
          this.get_date_class(this.display_date)
      }
  },
  data() {
      return {
          classes: [],
          userData: JSON.parse(localStorage.getItem('userData')),
          form: {
            class_id : null,
            member_code : null,
            class_data : null
          },
          display_date:moment(this.nowDate).format('dddd YYYY-MM-DD'),
          nowDate : new Date().getTime(),
          class_detail: {
            id:null,
            class_date:null,
            type:null,
            start:null,
            end:null,
            week_number:null,
            name:null,
            tutor:null,
            maximum:null,
            waiting:null
          },
          perPage:null,
          weekOptions : [
               { label : "SUNDAY",value:"0"},
               { label : "MONDAY",value:"1"},
               { label : "TUESDAY",value:"2"},
               { label : "WEDNESDAY",value:"3"},
               { label : "THURSDAY",value:"4"},
               { label : "FRIDAY",value:"5"},
               { label : "SATURDAY",value:"6"}
          ],
          statusOptions : [
               { label : "ACTIVE",value:"ACTIVE"},
               { label : "DISABLED",value:"DISABLED"},
               { label : "CANCELLED",value:"CANCELLED"},
               { label : "DELETED",value:"DELETED"}
          ],
      }
  },
  created() {
      this.get_date_class(moment(this.nowDate).format('YYYY-MM-DD'))
  },
  methods : {
    minus_day(day) {
      this.display_date = moment(this.display_date).subtract(day,'days').format('YYYY-MM-DD');
    },
    add_day(day) {
      this.display_date = moment(this.display_date).add(day,'days').format('YYYY-MM-DD');
    },
    get_date_class(date) {
      this.$http.get(process.env.VUE_APP_API_BASE+'/classes/'+this.$store.getters['center/currentCenterId']+'/'+date+'/')
        .then(res => {
          this.classes = res.data
         // this.display_date = res.data.date_formated
        })
    },
      get_class_detail(class_id,class_date) {
         this.$http.get(process.env.VUE_APP_API_BASE+'/class/'+class_id+'/?class_date='+class_date)
        .then(res => {
          this.class_detail = res.data
          this.form.class_id = res.data.id
          this.form.class_date = res.data.class_date
        })
      },
      attend(booking_id) {
         this.$http.post(process.env.VUE_APP_API_BASE+'/booking/'+booking_id+'/attend/')
        .then(res => {
          this.get_class_detail(this.class_detail.id,this.form.class_date)
        })
      },
      unattend(booking_id) {
         this.$http.post(process.env.VUE_APP_API_BASE+'/booking/'+booking_id+'/undo_attend/')
        .then(res => {
          this.get_class_detail(this.class_detail.id,this.form.class_date)
        })
      },
      update_class_detail() {
        this.$http.post(process.env.VUE_APP_API_BASE+'/class/update/',this.class_detail)
        .then(res => {
          if (res.data.result === true) {
            this.get_date_class(res.data.class_date)
            this.$toast({
                component: ToastificationContent,
                position: 'top-center',
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: res.data.message,
                },
              })
          }
        })
      },
      add_booking() {
        this.$http.post(process.env.VUE_APP_API_BASE+'/booking/add/',this.form)
        .then(res => {
         if (res.data.result === true) {
          this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            })
            this.get_class_detail(this.form.class_id,this.form.class_date)
            this.get_date_class(this.form.class_date)
            this.form = {'class_id' : null , 'class_date' : null , 'member_code' : null }
         } else {
           this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
         }
        })
      },
      delete_class(class_id) {
        this.$http.post(process.env.VUE_APP_API_BASE+'/class/'+class_id+'/delete/')
        .then(res => {
         if (res.data.result === true) {
          this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            })
            this.class_detail = {
            id:null,
            class_date:null,
            type:null,
            start:null,
            end:null,
            week_number:null,
            name:null,
            tutor:null,
            maximum:null,
            waiting:null
          },
            this.get_date_class(res.data.class_date)
         } else {
           this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
         }
        })
      },
      delete_booking(booking_id) {
        this.$http.post(process.env.VUE_APP_API_BASE+'/booking/'+booking_id+'/delete/')
        .then(res => {
         if (res.data.result === true) {
          this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            })
            this.get_class_detail(this.form.class_id,this.form.class_date)
            this.get_date_class(this.form.class_date)
         } else {
           this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
         }
        })
      }
  }
}
</script>
 <style>
    .pointer {
        cursor : pointer;
    }
    .form-group {
      margin-bottom:.3rem;
    }
 </style>
 <style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.yellow {
background-color: #ff9f43;
}
</style>
